import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import Chart from '../../components/Chart';
// import Breadcrumb from '../../components/Breadcrumb';
// import News from '../../components/News';
// import { Button } from '../../components/Base/BaseComponent';
// import { Typography, Card, Empty, Row } from 'antd';
// const { Title } = Typography;

/*const dataLine = [
	{name: 'January', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
	{name: 'February', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
    {name: 'March', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
    {name: 'April', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
    {name: 'May', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
    {name: 'June', 'Amala Member': Math.floor((Math.random() * 100) + 1)},
    {name: 'July', 'Amala Member': Math.floor((Math.random() * 100) + 1)}
];
const dataLine2 = [
    {name: 'January', 'Airline Redemmption': Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2400},
    {name: 'February', 'AirEmpty Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2210},
    {name: 'March', 'Airline Redemmption': Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2290},
    {name: 'April', 'Airline Redemmption': Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2000},
    {name: 'May', 'Airline Redemmption': Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2290},
    {name: 'June', 'Airline Redemmption': Math.floor((Math.random() * 100) + 1), 'Non Airline Redemmption': Math.floor((Math.random() * 100) + 1), amt: 2000},
];
const dataPie = [
    {name: 'Airline', value: Math.floor((Math.random() * 100) + 1)},
    {name: 'Non Air', value: Math.floor((Math.random() * 100) + 1)},
    {name: 'Private', value: Math.floor((Math.random() * 100) + 1)},
    {name: 'Other', value: Math.floor((Math.random() * 100) + 1)}
];*/
import { getGeneralConfig } from '../../utilities/Helpers';
import { general_config } from '../../utilities/Constant';
import ReactHtmlParser from 'react-html-parser';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			announcement: null
		}
	}

	componentDidMount() {
		document.title = "Dashboard | Loyalty Management System";

		/* Default announcement show */
		const callbackShow = (show) => {
			if (show === 'true' || show === true) {
				/* Default announcement */
				const callback = (announcement) => {
					this.setState({ announcement });
				}
				getGeneralConfig(general_config.default_announcement, callback);
			}
		}
		getGeneralConfig(general_config.default_announcement_show, callbackShow);
	}

	render() {
		const { announcement } = this.state;
		return (
			<React.Fragment>
				{/* <Title level={4}>News</Title>
				<Card hoverable size="small" extra={<a href="/news" className="btn-custom-transparent">More News</a>} style={{ width: '100%' }} bodyStyle={{ padding: 0 }}>
					<News />
					<Row gutter={24} className="news-entry-dashboard">
                    	<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No News today</span>} />
                	</Row>
				</Card> */}
				{ReactHtmlParser(announcement)}
			</React.Fragment>
		)
	}
}

export default Layout;