// DEVELOPMENT / STAGING / PRODUCTION
// let ENVIRONTMENT                = 'STAGING';
// let BASE_URL                    = "https://amala.garuda-indonesia.com/";
// let CONFIGURATION_URL_SERVICE   = {
//     DEVELOPMENT : 'http://172.25.230.78',
//     STAGING     : 'https://amala-api-pdt.garuda-indonesia.com',
//     PRODUCTION  : 'https://amala-api.garuda-indonesia.com'
// }

let CONFIGURATION_URL_SERVICE = process.env.REACT_APP_API_ENDPOINT;

let TOKEN_KEY       = 'id_token';
let PROFILE_KEY     = 'profile_token';
let SECRET_KEY      = 'asyst123';
let TIME_KEY        = 'time_token';
let PERMISSION_KEY  = 'permission_token';
let SESSION_TIME    = "60"; //minutes
let API_TOKEN_KEY   = 'api_token';
let VERSION_APP     = 'v1.2.2';
let MEMBERID        = 'memberid';

export const configuration = {
    // BASE_URL,
    // ENVIRONTMENT,
    // CONFIGURATION_URL_SERVICE: CONFIGURATION_URL_SERVICE[ENVIRONTMENT],
    CONFIGURATION_URL_SERVICE,
    TOKEN_KEY,
    PROFILE_KEY,
    SECRET_KEY,
    TIME_KEY,
    PERMISSION_KEY,
    SESSION_TIME,
    API_TOKEN_KEY,
    VERSION_APP,
    MEMBERID
}