import React from "react";
import { Result, Col, Row, Form, Skeleton, Typography, Statistic, Icon } from "antd";
import { api } from "../../../config/Services";
import { EmailChecklist } from "../../../components/IconSVG/index";
import { SaveRequest, RetrieveRequest, DetailRequest, } from "../../../utilities/RequestService";
import { loginNoAuth, setIdToken, setTimeToken, setProfile, setAPIToken, } from "../../../utilities/AuthService";
import { Alert, Button } from "../../../components/Base/BaseComponent";
import { jwtDecode } from "jwt-decode";
import OtpInput from "react-otp-input";
import moment from "moment";
import momentzone from "moment-timezone";

const { Title, Text } = Typography;
const { Countdown } = Statistic;

class ProfileUpdateOtp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			otpvalue: false,
			isLoading: false,
			otprender: true,
			verified: false,
			fieldvalue: {
				generatedtime: 0,
				expiredtime: 0,
			},
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		document.title = "Verification OTP | Loyalty Management System";

		const formData = {};
		formData["username"] = "amalafe";
		formData["password"] = "amalafe2019";

		const callback = async (response) => {
			const { result, status } = response.data;
			const { access_token } = result;
			const { responsecode, responsemessage } = status;

			if (responsecode === "0000") {
				await setAPIToken(access_token);
				await setIdToken("abcd");
				await setTimeToken();

				let url = api.url.user.list;
				let criteria = { username: formData.username };
				await RetrieveRequest(url, criteria).then((response) => {
					setProfile(result, formData.username, response.result[0]);
				});
				this.getOTPSession();
			} else Alert.error(responsemessage);
			this.setState({ isLoading: false });
		};

		loginNoAuth(formData, callback);
	}

	getOTPSession = () => {
		this.setState({ isLoading: true });
		let otpcode = this.props.location.search.split("=")[1];
		if (otpcode) otpcode = jwtDecode(otpcode);

		const criteria = { otpcode: otpcode.otpcode };
		RetrieveRequest(api.url.memberotp.generatetime, criteria).then((response) => {
			const { status, result } = response || {};
			const { generatedtime, expiredtime, memberid, additionaldata, otpsessionid, transactiontype } = result && result.length !== 0 ? result[0] : {};

			if (status.responsecode === "0000") {
				if (result.length !== 0) {
					const timeExpiredServer = moment(expiredtime).format('YYYY/MM/DD HH:mm:ss')
					const currentLocalTimetoServer = momentzone().tz('Asia/Jakarta').format('YYYY/MM/DD HH:mm:ss');

					if (timeExpiredServer && (moment(currentLocalTimetoServer) <= moment(timeExpiredServer))) {
						this.setState({
							memberid,
							otpvalue: otpcode.otpcode,
							transactiontype,
							isLoading: false,
							data: (additionaldata) ? JSON.parse(additionaldata) : {},
							otprender: true,
							fieldvalue: { ...this.state.fieldvalue, generatedtime, expiredtime },
						});
					} else this.setState({ errortype: 'expired', otprender: false, messageSubTitle: "The OTP code has expired, please retry for a new code" });
					this.setState({ fieldvalue: { ...this.state.fieldvalue, otpsessionid } });
				} else this.setState({ errortype: 'invalid', otprender: false, messageSubTitle: "The OTP code has invalid, please generate new or check latest OTP code we send to you" });
			} else {
				Alert.error(status.responsemessage);
				this.setState({ isLoading: false, errortype: 'service', otprender: false, messageSubTitle: "Please check your internet connection and try again" });
			};
		});
	};

	handleOTP = (value, type) => {
		const { otpvalue, transactiontype, memberid } = this.state;
		if (type === "verifyotp") {
			this.setState({ isLoading: true });
			const data = {
				memberid,
				transactiontype,
				otpcode: Number(otpvalue),
				channel: "BO",
			}

			DetailRequest(api.url.memberotp.verification, data).then((response) => {
				const { status, result } = response;
				const { responsecode, responsemessage } = status || {};
				const { transactiontype, verificationstatus } = result ? result : "";
				if (responsecode === "0000") {
					let message = responsemessage ? responsemessage : "";
					this.setState({ transactiontype, verificationstatus, verified: true, });
					if (transactiontype !== "REDEMPTION") this.handleUpdateProfile();
					Alert.success(message);
				} else Alert.error(responsemessage);
				this.setState({ isLoading: false });
			});
		} else this.setState({ otpvalue: value });
	};

	handleUpdateProfile = () => {
		const memberid = this.state;
		const data = this.state.data;
		const url = api.url.member.update;

		SaveRequest(url, data).then((response) => {
			const { responsecode, responsemessage } = response.status;
			if (responsecode === "0000") {
				Alert.success(responsemessage);
			} else if (memberid !== null) Alert.error(responsemessage);
			this.setState({ isLoading: false });
		});
	};

	handleChangeOTP = (value) => {
		this.setState({ otpvalue: value });
	};

	render() {
		const { isLoading, otpvalue, otprender, fieldvalue, verified, messageSubTitle, errortype } = this.state;
		const formItemLayout = {
			labelCol: { xs: { span: 24 }, sm: { span: 8 } },
			wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
		};

		const mobileScreen = window.screen.width < 600;
		
		const timeExpiredServer = (fieldvalue) ? moment(fieldvalue.expiredtime).format('YYYY/MM/DD HH:mm:ss') : momentzone().tz('Asia/Jakarta').format('YYYY/MM/DD HH:mm:ss');
		const currentLocalTimetoServer = momentzone().tz('Asia/Jakarta').format('YYYY/MM/DD HH:mm:ss');
		const difference = (timeExpiredServer) ? moment(timeExpiredServer).diff(currentLocalTimetoServer) : null;
		const countdown = Date.now() + difference;

		if (isLoading) {
			return <Skeleton active={true}></Skeleton>;
		} else if (verified) {
			return (
				<Row type="flex" justify="center">
					<Col>
						<Row type="flex" justify="center" style={{ margin: mobileScreen ? '70px 0px 0px 0px' : '70px 0px' }}>
							<img src="/assets/images/logoGA.png" alt="GA Logo" width={mobileScreen ? 180 : 300} style={{ marginRight: (mobileScreen) ? 0 : 80 }} />
							<img src="/assets/images/logo.png" alt="Asyst Logo" width={mobileScreen ? 130 : 190} />
						</Row>
						<Result
							icon={<Icon component={EmailChecklist} {...this.props} />}
							title="Congratulations!"
							subTitle="OTP verified successfully"
						/>
					</Col>
				</Row>
			)
		} else return (
			<Row>
				{(otprender) ? (
					<Form {...formItemLayout}>
						<Row>
							<Row type="flex" justify="center">
								<Col className="gutter-row" xs={24} style={{ textAlign: "center", marginTop: 120 }}>
									<Row type="flex" justify="center" style={{ margin: mobileScreen ? '70px 0px 0px 0px' : '70px 0px' }}>
										<img src="/assets/images/logoGA.png" alt="GA Logo" width={mobileScreen ? 180 : 300} style={{ marginRight: (mobileScreen) ? 0 : 80 }} />
										<img src="/assets/images/logo.png" alt="Asyst Logo" width={mobileScreen ? 130 : 190} />
									</Row>
									<Title level={2}>Verification</Title>
									<Text strong style={{ marginTop: 20 }}>
										Please insert OTP Code before
									</Text>
									<strong>
										<Countdown
											valueStyle={{ fontSize: "16px" }}
											value={countdown}
											format="mm:ss"
											onFinish={() => {
												this.setState({ otprender: false, messageSubTitle: "The OTP code has expired, please retry for a new code" });
											}}
										/>
									</strong>
									<Row justify="center" type="flex" style={{ margin: 15 }}>
										<OtpInput
											value={otpvalue}
											shouldAutoFocus={true}
											onChange={this.handleChangeOTP}
											numInputs={6}
											renderSeparator={<span>&nbsp;&nbsp;</span>}
											renderInput={(props) => <input {...props} />}
											inputStyle={{
												width: 50,
												height: 50,
												borderRadius: "8px",
												borderColor: "rgba(0, 0, 0, 0.65)",
											}}
										/>
									</Row>
									<Text strong style={{ marginTop: 30 }}>
										Please contact customer service if you don't receive an email or resend code
									</Text>
								</Col>
							</Row>
							<Row type="flex" justify="center" style={{ margin: 15 }}>
								<Button
									htmlType="button"
									type="primary"
									label="Verify"
									onClick={(val) => this.handleOTP(val, "verifyotp")}
								/>
							</Row>
						</Row>
					</Form>
				) : (
					<Row type="flex" justify="center">
						<Col>
							<Row type="flex" justify="center" style={{ margin: mobileScreen ? '70px 0px 0px 0px' : '70px 0px' }}>
								<img src="/assets/images/logoGA.png" alt="GA Logo" width={mobileScreen ? 180 : 300} style={{ marginRight: (mobileScreen) ? 0 : 80 }} />
								<img src="/assets/images/logo.png" alt="Asyst Logo" width={mobileScreen ? 130 : 190} />
							</Row>
							<Result
								icon={<Icon type={(errortype === 'service') ? "api" : (errortype === 'invalid') ? "close-circle" : "clock-circle"} theme={(errortype === 'invalid') ? "filled" : "twoTone"} twoToneColor="#f5222d" />}
								status="error"
								title={(errortype === 'service') ? "Connection Issue" : "Failed to verify OTP"}
								subTitle={messageSubTitle}
							/>
						</Col>
					</Row>
				)}
			</Row>
		);
	}
}

export default ProfileUpdateOtp;